import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { CONSTANTS } from '../../../constants';
import { ENVIRONMENT } from '../../../../../environments/environment';
import { FootprintDetail } from '../../../../catalog/footprint/footprint-detail';
import { HandleError } from '../../../../utils/utils';
import { InputFieldConfiguration } from '../../../../administration/input-field-configuration/input-field-configuration';
import { InventoryDetail } from '../../../../inventory/inventory-detail';
import { InventoryHeader } from '../../../../inventory/inventory-header';
import { Location } from '../../../../catalog/location/location';
import { Lpn } from '../../../../lpn/lpn';
import { MovementTaskDetail } from './movement-task-detail';
import { Parameter } from '../../../../process/picking-list/parameter';
import { Picking } from '../../../../process/picking/picking';
import { PickingTaskWrapperDetail } from './picking-task-detail';
import { ReleaseTask } from '../../../interfaces/release-task';
import { Session } from '../../../../session/session';
import { SessionService } from '../../../../session/session.service';
import { ShipmentOrder } from '../../../../process/picking/shipment-order/shipment-order';
import { ShipmentOrderDetail } from '../../../../process/picking/shipment-order/shipment-order-detail';
import { Task } from '../../../../process/task/task';
import { TaskDetail } from '../../../../process/task/task-detail';
import { TaskHeader } from '../../../../process/task/task-header';
import { TaskTypeEnum } from './../../../../process/task/task-enums';
import { Wave } from '../../../../planning/wave/wave';

@Injectable()
export class TaskGenericWrapperService {
  constructor(private http: HttpClient,
              private sessionService: SessionService) { }

  /**
   * @description Picking Type set method.
   * @param {Any[]} pickingType list to store in localstorage.
   * @return {void}
   */
  public setPickingTypeList(pickingType: any[]): void {
    localStorage.setItem('pickingType', JSON.stringify(pickingType));
  }

  /**
   * @description Class get method.
   * @return {Task[]} List of Picking Type.
   */
  public getPickingTypeList(): Task[] {
    return JSON.parse(localStorage.getItem('pickingType'));
  }

  /**
   * @description Work Reference List set method.
   * @param {Task[]} workReferences  Work Reference List to store in localstorage.
   * @return {void}
   */
  public setWorkReferenceListLocal(workReferences: Task[]): void {
    localStorage.setItem('workReferences', JSON.stringify(workReferences));
  }

  /**
   * @description Class get method.
   * @return {Task[]} List of Work Reference List.
   */
  public getWorkReferenceListLocal(): Task[] {
    return JSON.parse(localStorage.getItem('workReferences'));
  }

  /**
   * @description Order list set method.
   * @param {ShipmentOrder[]} orders Order list to store in localstorage.
   * @return {void}
   */
  public setOrdersListLocal(orders: ShipmentOrder[]): void {
    localStorage.setItem('orders', JSON.stringify(orders));
  }

  /**
   * @description Class get method.
   * @return {ShipmentOrder[]} List of orders.
   */
  public getOrderListLocal(): ShipmentOrder[] {
    return JSON.parse(localStorage.getItem('orders'));
  }

  /**
   * @description Wave List set method.
   * @param {Wave[]} waves Waves to store in localstorage.
   * @return {void}
   */
  public setWaveListLocal(waves: Wave[]): void {
    localStorage.setItem('waves', JSON.stringify(waves));
  }

  /**
   * @description Class get method.
   * @return {wave[]} List of waves.
   */
  public getWavesListLocal(): Wave[] {
    return JSON.parse(localStorage.getItem('waves'));
  }

  /**
   * @description Task previosly selected.
   * @param {Task} taskSelected Selected task to store in localstorage.
   * @return {void}
   */
  public setTaskSelected(taskSelected: Task): void {
    localStorage.setItem('taskSelected', JSON.stringify(taskSelected));
  }

  /**
   * @description Class get method.
   * @return {Task} Task Selected.
   */
  public getTaskSelectedLocal(): Task {
    return JSON.parse(localStorage.getItem('taskSelected'));
  }

  /**
   * @description Get all the picking's that contains in DB.
   * @return {Observable<any[]>} - List of Picking types.
   */
  public getPickings(): Observable<any[]> {
    return this.http.get<any[]>(ENVIRONMENT.API + '/picking-list/picking-list-types/enum/')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get all the Work References that contains in DB.
   * @param {number} accountId - Id about the account.
   * @param {string} taskType - Task type to search
   * @param {number} userId - user id related to work zone
   * @param {number} shipmentOrderId Number that contains the propertie of shipmentOrderId.
   * @return {Observable<Task[]>} - List of Work References.
   */
  public getWorkReferenceList(accountId: number, taskType: string, userId: number, shipmentOrderId?: number): Observable<Task[]> {
    let criteria = [
      {
        'field': 'task.account_id',
        'condition': '=',
        'value': accountId
      },
      {
        'field': 'task.type',
        'condition': '=',
        'value': taskType
      },
      {
        'field': 'assignment_zone_user_destination_location.user_id',
        'condition': '=',
        'value': userId
      },
      {
        'field': 'assignment_zone_user_origin_location.user_id',
        'condition': '=',
        'value': userId
      }
    ];

    if (shipmentOrderId) {
      criteria.push({
        'field': 'shipment_order.id',
        'condition': '=',
        'value': shipmentOrderId
      });
    }
    return this.http.get<Task[]>(ENVIRONMENT.API + '/picking-list/work-reference/?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get all the Waves that contains in DB.
   * @param {number} id Id with properties about account.
   * @param {string} taskType Task type to search.
   * @param {number} userId - user id related to work zone
   * @return {Observable<Wave[]>} - List of Waves.
   */
  public getWaveList(id: number, taskType: string, userId: number): Observable<Wave[]> {
    let criteria = [
      {
        'field': 'shipment_order.account_id',
        'condition': '=',
        'value': id
      },
      {
        'field': 'task.type',
        'condition': '=',
        'value': taskType
      },
      {
        'field': 'assignment_zone_user_destination_location.user_id',
        'condition': '=',
        'value': userId
      },
      {
        'field': 'assignment_zone_user_origin_location.user_id',
        'condition': '=',
        'value': userId
      }
    ];
    return this.http.get<Wave[]>(ENVIRONMENT.API + '/picking-list/wave/?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get all the orders that contains in DB.
   * @param {number} accountId Number that contain the id about the select account.
   * @param {string} taskType Task type to search.
   * @param {number} userId - user id related to work zone
   * @param {number} waveId Id about previous wave selected.
   * @return {Observable<ShipmentOrder[]>} - List of Orders.
   */
  public getOrderList(accountId: number, taskType: string, userId: number, waveId?: number): Observable<ShipmentOrder[]> {
    let criteria = [
      {
        'field': 'shipment_order.account_id',
        'condition': '=',
        'value': accountId
      },
      {
        'field': 'task.type',
        'condition': '=',
        'value': taskType
      },
      {
        'field': 'assignment_zone_user_destination_location.user_id',
        'condition': '=',
        'value': userId
      },
      {
        'field': 'assignment_zone_user_origin_location.user_id',
        'condition': '=',
        'value': userId
      }
    ];

    if (waveId) {
      criteria.push(
        {
          'field': 'wave.id',
          'condition': '=',
          'value': waveId
        }
      );
    }
    return this.http.get<ShipmentOrder[]>(ENVIRONMENT.API + '/picking-list/order/?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get details about selected picking.
   * @param {number} taskId Number that contain taskId of selected list.
   * @return {Observable<PickingTaskDetail[]>} - Specific list.
   */
  public getTaskSelected(taskId: number): Observable<PickingTaskWrapperDetail[]> {
    return this.http.get<PickingTaskWrapperDetail[]>(ENVIRONMENT.API + '/picking-list/picking-list-detail/' + taskId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method assigns the task when confirming a step.
   * @param {number} taskId Number that contain the selected task.
   * @return {Observable<Task>}
   */
  public setTaskAssigned(task: Task): Observable<Task> {
    return this.http.post<Task>(ENVIRONMENT.API + '/picking-list/assign-task', task)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method changes the status of the task to progress.
   * @param {Task} task Contain the selected task.
   * @return {Observable<Task>}
   */
  public setInProgressTask(task: Task): Observable<Task> {
    return this.http.post<Task>(ENVIRONMENT.API + '/picking-list/progress-task', task)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method return the input field configuration about the task Selected.
   * @param {number} areaId Number that contains the area of originLocation to search inputFieldConfiguration.
   * @param {number} accountId Number that contains the account id to search inputFieldConfiguration.
   * @param {string} inputFieldTaskType Task type of the input field configuration
   * @return {Observable<InputFieldConfiguration[]>}
   */
  public getInputFieldConfiguration(areaId: number, accountId: number, inputFieldTaskType: string): Observable<InputFieldConfiguration[]> {
    let criteria = [{
      'field': 'input_field_configuration.area_id',
      'condition': '=',
      'value': areaId
    },
    {
      field: 'input_field_configuration.account_id',
      condition: '=',
      value: accountId
    },
    {
      'field': 'input_field_configuration.task_type',
      'condition': '=',
      'value': inputFieldTaskType
    }];
    return this.http.get<InputFieldConfiguration[]>(ENVIRONMENT.API + '/input-field-configuration/?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method validate the lpn Origin entered in check inventory.
   * @param {string} lpnOrigin Param that contains the string that check in inventory.
   * @param {number} originLocationId Param that contains the string about origin Location.
   * @param {number} productId Param that contain the product to validate.
   * @param {string} taskType Param that contain the type of the task.
   * @param {number} quantity Param that contain the ordered quantity by task detail.
   * @param {number} minShelfLife param that contains the minimum shelf life if applies
   * @return {Observable<InventoryDetail[]>} - Response LPN.
   */
  public validateLpnInCheckInventory(lpnOrigin: string, originLocationId: number, productId: number, taskType: string, quantity?: number,
    minShelfLife?:  number):
    Observable<InventoryDetail[]> {
    let pathBuilder: string;
    let criteria: Object[] = [];
    if (isEqual(taskType, CONSTANTS.PICKING_PALLET_TYPE) || isEqual(taskType, CONSTANTS.PICKING_PALLET_WORKING_ORDER_TYPE)) {
      criteria.push({
        'field': 'invdet.quantity', 'condition': '=', 'value': quantity
      });
    } else {
      criteria.push({
        'field': 'invdet.quantity', 'condition': '>', 'value': CONSTANTS.ZERO
      });
    }

    if (originLocationId) {
      criteria.push({ 'field': 'location_id', 'condition': '=', 'value': originLocationId });
    }

    if (productId) {
      criteria.push({ 'field': 'product_id', 'condition': '=', 'value': productId });
    }

    if (lpnOrigin) {
      criteria.push({ 'field': 'lpn', 'condition': '=', 'value': lpnOrigin });
    }

    pathBuilder = 'criteria=' + JSON.stringify(criteria);

    if (minShelfLife && minShelfLife > CONSTANTS.ZERO) {
      let dateOffset =  moment().add(minShelfLife, CONSTANTS.DAY).format(CONSTANTS.YEAR_FORMAT);
      pathBuilder = pathBuilder + '&minShelfLife=' + dateOffset;
    }
    return this.http.get<InventoryDetail[]>(ENVIRONMENT.API + '/picking-list/check-inventory?' + pathBuilder)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Validates if theres available inventory by the given criteria and return the location
   * @param {string} location Parameter that contains the location to check in inventory.
   * @param {number} productId Parameter that contain the product to check in inventory.
   * @param {number} footprintId Parameter that contain the footprint to check in inventory.
   * @param {string} lot Parameter that contain the lot to check in inventory.
   * @param {number} orderedQuantity Parameter that contain the quantity to check in inventory.
   * @param {number} userId - user id related to work zone.
   * @return {Observable<Location>} Location where is inventory data
   */
  public checkInventory(location: string, productId: number, footprintId: number, lot: string,
    orderedQuantity: number, userId?: number): Observable<Location> {
    let criteria: Object[] = [];
    criteria.push({ 'field': 'location.name', 'condition': '=', 'value': location });
    criteria.push({ 'field': 'product.id', 'condition': '=', 'value': productId });
    criteria.push({ 'field': 'invdet.lot', 'condition': '=', 'value': lot });
    criteria.push({ 'field': 'invdet.quantity', 'condition': '>=', 'value': orderedQuantity });
    criteria.push({ 'field': 'invdet.footprint_id', 'condition': '=', 'value': footprintId });

    return this.http.get<Location>(ENVIRONMENT.API + '/inventory/check-inventory-location?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
    * @description Validate if is posible cut picking list
    * @param {number} taskId task id to validate.
    * @returns Observable<Task> task if is posible cut picking list.
    */
  public validateCutPickedList(taskId: number): Observable<Task> {
    return this.http.get<Task>(ENVIRONMENT.API + '/picking-list/validate-picked-list/' + taskId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Updates picking list task status to available.
   * @param {Task | ReleaseTask} task task to update
   * @returns Observable<Task> task updated.
   */
  public releasePickingList(task: Task | ReleaseTask): Observable<Task> {
    return this.http.put<Task>(ENVIRONMENT.API + '/picking-list/available-task/' + task.id, task)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Updates picking list task status to done.
   * @param {number} taskId task id to update
   * @returns Observable<Task> task updated.
   */
  public completePickingList(taskId: number): Observable<Task> {
    return this.http.put<Task>(ENVIRONMENT.API + '/picking-list/completed-task/' + taskId, '')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method return the params validating by the module sent.
   * @param {number} module Module to validate the type of parameter sent.
   * @param {string} param Existing param for specific search of the database.
   * @return {Observable<Parameter>}
   */
  public validateParam(module: number, param: string): Observable<Parameter> {
    return this.http.get<Parameter>(ENVIRONMENT.API + '/parameter/' + module + '/' + param)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method validate the lpn entered by the user.
   * @param {string} lpn - lpn entered by the user.
   * @param {number} accountId - The account id to look up for.
   * @return {Observable<Lpn>} - Lpn id of the validated label.
   */
  public validateLpn(lpn: string, accountId: number): Observable<Lpn> {
    return this.http.get<Lpn>(ENVIRONMENT.API + '/lpn/validateLpn/' + lpn + '?accountId=' + accountId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method un-assigned the task previously selected.
   * @param {Task} task Object that contain all the properties of task.
   * @return {Observable<Task>}
   */
  public unlockTast(task: Task): Observable<Task> {
    return this.http.post<Task>(ENVIRONMENT.API + '/picking-list/un-assign-task', task)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method validates whether the inventory has existence of the product.
   * @param {number} taskDetailId Number that contains the id about taskDetails in progress.
   * @param {string} sku Sku that will be search in progression DB.
   * @return {Observable<TaskDetail>}
   */
  public validateInventory(taskDetailId: number, sku: string): Observable<TaskDetail> {
    let criteria = [
      {
        'field': 'product.sku',
        'condition': '=',
        'value': sku
      }];
    return this.http.get<TaskDetail>(ENVIRONMENT.API +
      '/picking-list/check-detail/' + taskDetailId + '?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method validate the lot entered by user in check inventory.
   * @param {string} lpnOrigin Param that contains the string that check in inventory.
   * @param {number} originLocationId Param that contains the string about origin Location.
   * @param {number} productId Param that contain the product to validate.
   * @param {string} lot Param that contains the lot about the user entered.
   * @return {Observable<InventoryDetail[]>}
   */
  public validateLotInCheckInventory(lpnOrigin: string, originLocationId: number,
    productId: number, lot: string): Observable<InventoryDetail[]> {
    let criteria = [
      {
        'field': 'location_Id',
        'condition': '=',
        'value': originLocationId
      },
      {
        'field': 'product_Id',
        'condition': '=',
        'value': productId
      },
      {
        'field': 'lpn',
        'condition': '=',
        'value': lpnOrigin
      },
      {
        'field': 'lot',
        'condition': '=',
        'value': lot
      }
    ];
    return this.http.get<InventoryDetail[]>(ENVIRONMENT.API + '/inventory/check-inventory?criteria=' + JSON.stringify(criteria))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method return the footprint detail data.
   * @param {number} footprintId Number that contains the footprint of the product.
   * @param {number} footPrintDetailId Number that contains the detail id of the footprint.
   * @return {Observable<FootprintDetail>}
   */
  public getFootPrintDetail(footprintId: number, footPrintDetailId: number): Observable<FootprintDetail> {
    return this.http.get<FootprintDetail>(ENVIRONMENT.API + '/footprints/' + footprintId + '/footprint-details/' + footPrintDetailId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method receipt and validate the PickingGenericWrapper that will be send.
   * @param {PickingTaskWrapperDetail} confirmPickingGeneric Object that contains the properties of PLD.
   * @param {number} accountId Number that contains the id about account selected.
   * @return {Observable<Picking>}
   */
  public validatePickingSent(confirmPickingGeneric: any, accountId: number): Observable<Picking> {
    return this.http.post<Picking>(ENVIRONMENT.API + '/picking-list/confirm-task-detail/' + accountId, confirmPickingGeneric)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
    * @description This method return the confirmation about movement to shipment.
    * @param {number} accountId Object that contain the properties to send confirmation in shipment.
    * @param {MovementTaskDetail} movementTaskDetailgeneric object to move task to destination location.
    * @return {Observable<Picking>}
    */
  public moveToShipment(accountId: number, movementTaskDetail: MovementTaskDetail): Observable<Picking> {
    return this.http.post<Picking>(ENVIRONMENT.API + '/picking-list/move-to-shipment/' + accountId, movementTaskDetail)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description confirm all picked tasks .
   * @param {number} accountId Account id.
   * @param {MovementTaskDetail[]} movementTasks tasks to move to destination location.
   * @param {Location} doorLocation  door location for fluid chargue, optional value
   * @return {Observable<void>}
   */
   public moveToShipmentPickingPallet(accountId: number, movementTasks: MovementTaskDetail[], doorLocation?: Location): Observable<void> {
    let objectBody: Object = {
      'movementTasks': movementTasks,
      'doorLocation': !_.isNil(doorLocation) ? doorLocation : undefined,
      'accountId': accountId
    };
    return this.http.post<void>(ENVIRONMENT.API + '/picking-pallet/move-to-shipment-multilpe-lpns/' + accountId, objectBody)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Updates picking list task status to available.
   * @param {Task} task task to update
   * @param {Object} criteria - criteria for update task
   * @returns Observable<Task> task updated.
   */
  public releaseTaskList(task: Task, criteria: Object): Observable<Task> {
    let criteriaStr: string = criteria ? '?criteria=' + JSON.stringify(criteria) : '';
    return this.http.put<Task>(ENVIRONMENT.API + '/picking-list/available-task/' + task.id + criteriaStr, task)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get details about selected picking.
   * @param {number} taskId Number that contain taskId of selected list.
   * @return {Observable<PickingTaskDetail[]>} - Specific list.
   */
  public getReplenishmentTaskSelected(taskId: number): Observable<PickingTaskWrapperDetail[]> {
    return this.http.get<PickingTaskWrapperDetail[]>(ENVIRONMENT.API + '/replenishment-task/replenishment-detail/' + taskId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get shipment order details by task detail.
   * @param {TaskDetail} taskDetail Task detail info.
   * @return {Observable<ShipmentOrderDetail[]>} Shipment order details found.
   */
  public getShipmentOrderDetailsByTaskDetail(taskDetail: TaskDetail): Observable<ShipmentOrderDetail[]> {
    return this.http.get<ShipmentOrderDetail[]>(ENVIRONMENT.API + '/shipment-order/detail-by-task-detail/' + taskDetail.id)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Cancels the current task
   * @param {number} taskId task id to cancel
   * @param {Session} session object with session data
   * @returns Observable<Task> task cancelled.
   */
  public cancelTask(taskId: number, session: Session): Observable<Task> {
    let payload: Object = {
      id: taskId,
      warehouseId: session.warehouseId,
      userId: session.userId,
      accountId: session.accountId
    };
    return this.http.post<Task>(ENVIRONMENT.APIPLANNING + '/cancel-task/', payload)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description updates lpn data on task header
   * @param {number} taskHeaderId identifier of the task header to be updated.
   * @param {Lpn} lpn lpn data that will update the task header
   * @return {Observable<Task>}
   */
  public updateTaskHeaderLpn(taskHeaderId: number, lpn: Lpn): Observable<TaskHeader> {
    return this.http.put<TaskHeader>(ENVIRONMENT.API + '/task/update-task-header/' + taskHeaderId, lpn)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
  * @description Set is available in lpn for pending work process
  * @param {string} lpn - lpn to validate
  * @return {Observable<Lpn>} - Lpn updated
  */
  public setIsAvailableLpn(lpn: string): Observable<Lpn> {
    let session: Session = this.sessionService.getUserSession();
    let lpnEncoded: string = encodeURIComponent(lpn);
    return this.http.post<Lpn>(ENVIRONMENT.API + '/lpn-reusable/set-is-available/' + lpnEncoded, JSON.stringify(session))
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method generate bulk picking task.
   * @param {number} accountId Object that contain the properties to send confirmation in shipment.
   * @param {MovementTaskDetail} movementTaskDetail Object that contain the pickingDetail.
   * @return {Observable<Task>}
   */
  public generateBulkPickingTask(accountId: number, movementTaskDetail: MovementTaskDetail): Observable<Task> {
    return this.http.post<Task>(ENVIRONMENT.API + '/bulk-picking/generate-task/' + accountId, movementTaskDetail)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Validates if the serial exists for the given product
   * @param {string} serialNumber serial number
   * @param {number} productId product id
   * @return {Observable<void} Shipment order details found.
   */
  public validateSerialByProduct(serialNumber: string, productId: number): Observable<void> {
    return this.http.get<void>(ENVIRONMENT.API + '/serial/' + serialNumber + '/product/' + productId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Updates picking list task status to available.
   * @param {Task} task task object to validate
   * @returns {Observable<Task>} task object valid.
   */
  public validateAssignedTask(task: Task): Observable<Task> {
    return this.http.get<Task>(ENVIRONMENT.API + '/task/validate-assigned-task/' + task.id)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Validates if theres available inventory by the given criteria and return the location
   * @param {string} location Parameter that contains the location to check in inventory.
   * @param {number} productId Parameter that contain the product to check in inventory.
   * @param {number} footprintId Parameter that contain the footprint to check in inventory.
   * @param {string} lot Parameter that contain the lot to check in inventory.
   * @param {number} orderedQuantity Parameter that contain the quantity to check in inventory.
   * @param {string} taskType - user id related to work zone.
   * @return {Observable<InventoryHeader[]>} Location where is inventory data
   */
  public checkInventorAndGetSuggestedLpn(location: string, productId: number, footprintId: number, lot: string,
    orderedQuantity: number, taskType: string): Observable<InventoryHeader[]> {
    let criteria: Object[] = [];
    criteria.push({ 'field': 'location.name', 'condition': '=', 'value': location });
    criteria.push({ 'field': 'inventory_location.product_id', 'condition': '=', 'value': productId });
    criteria.push({ 'field': 'invdet.lot', 'condition': '=', 'value': lot });
    criteria.push({ 'field': 'invdet.footprint_id', 'condition': '=', 'value': footprintId });
    if (isEqual(taskType, CONSTANTS.PICKING_PALLET_TYPE) || isEqual(taskType, CONSTANTS.PICKING_PALLET_WORKING_ORDER_TYPE)) {
      criteria.push({
        'field': 'invdet.quantity', 'condition': '=', 'value': orderedQuantity
      });
    } else {
      criteria.push({
        'field': 'invdet.quantity', 'condition': '>', 'value': CONSTANTS.ZERO
      });
    }

    return this.http.get<InventoryHeader[]>(ENVIRONMENT.API + '/inventory/suggest-lpn-by-task/' + taskType + '?criteria='
      + JSON.stringify(criteria)).pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Get picking pallet task about assigned by user.
   * @return {Observable<Task>}
   */
  public getAssignedTask(originLocation: Location, destinationLocation: Location): Observable<Task> {
    let payload: Object = {
      originLocation: originLocation,
      destinationLocation: destinationLocation
    };
    return this.http.post<Task>(ENVIRONMENT.API + '/task/directed-work-multiple-lpns/' + TaskTypeEnum[TaskTypeEnum.PickingPallet],
      payload)
    .pipe(catchError(HandleError.handleErrorObservable));
  }
}

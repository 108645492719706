import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { replace } from 'lodash';

import { ENVIRONMENT } from '../../../environments/environment';
import { HandleError } from '../../utils/utils';
import { Label } from './label';

const GENERIC_ERROR = 'GENERIC_ERROR';
const LABEL_FOR = 'Label for ';
const LABEL_PARAM_KEY_WORD = '%t';
const LOCALSTORAGE_MODULE = 'labels';
const NOT_FOUND = 'not found';

@Injectable()
export class LabelService {
  public genericError: Label;
  public label: Label;
  public labels: Label[];

  constructor(private http: HttpClient) {
    this.label = new Label();
    this.labels = [];
    if (localStorage.getItem(LOCALSTORAGE_MODULE)) {
      this.labels = JSON.parse(localStorage.getItem(LOCALSTORAGE_MODULE));
      this.genericError = this.labels.find(foundLabel => foundLabel.errorCode === GENERIC_ERROR);
    }
  }

  /**
   * @description Calls to the service to change a label by sending as the body the edited label
   * @param {Label} label label to be overwritten in the file
   * @return {Observable<Label>}
   */
  public changeLabel(label: Label): Observable<Label> {
    return this.http.put<Label>(ENVIRONMENT.API + '/tags', label)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description This method returns all the labels from the backend
   * @return {Observable<Label[]>}
   */
  public getSystemLabels(): Observable<Label[]> {
    return this.http.get<Label[]>(ENVIRONMENT.API + '/tags')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets the labels in a module.
   * @param {string} _module Module's Id
   * @return labels by module
   */
  public getLabelsByModule(_module: string): Label[] {
    let labels: Label[] = [];
    if (this.labels) {
      labels = this.labels.filter(label => label.module === _module);
    }
    return labels;
  }

  /**
   * @description This method returns the label according to the module, view and key
   * @param {string} _key Key or id
   * @return {string} Label's according to the ids
   */
  public labelText(_key: string): string {
    let label: Label;
    if (this.labels) {
      label = this.labels.find(_label => _label.key === _key);
    }
    return label ? label.label : LABEL_FOR + _key + NOT_FOUND;
  }

  /**
   * @description Returns error message according to Wep error message
   * @param {String} errorCode String key to look error by
   * @return {String} Error message
   */
  public getWepError(errorCode: string): string {
    if (this.labels) {
      this.label = this.labels.find(foundLabel => foundLabel.errorCode === errorCode);
      this.label = this.label ? this.label : this.genericError;
    }
    return this.label.label;
  }

  /**
   * @description Replace all keys in message for the values received in a string array
   * @param {string} message - Message to update
   * @param {string[]} values - Array with values that will be replaced
   * @returns {string} - Message with keys replaced
   */
  public buildMessageParams(message: string, values: string[]): string {
    const regExp: RegExp = new RegExp(LABEL_PARAM_KEY_WORD, 'gi');
    return replace(message, regExp, () => values.shift());
  }
}

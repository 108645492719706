import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { ScfToastrModule } from 'scf-library';

import { DashboardConfigurationService } from './administration/dashboard-configuration/dashboard-configuration.service';
import { DashboardService } from './dashboard/dashboard.service';
import { HistoryComponent } from './shared/history/history.component';
import { HomeComponent } from './home/home.component';
import { LabelService } from './administration/label-management/label.service';
import { LocationService } from './catalog/location/location.service';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { PolicyParamService } from './administration/policy-param/policy-param.service';
import { ReleaseTaskService } from './shared/services/release-task.service';
import { SessionComponent } from './session/session.component';
import { SessionService } from './session/session.service';
import { SidebarWrapperModule } from './shared/mobile-sidebar/sidebar-wrapper.module';
import { TaskGenericWrapperService } from './shared/templates/html/task-wrapper/task-wrapper.service';
import { UserService } from './administration/user/user.service';
import { UtilsModule } from './utils/utils.module';
import { WarehouseService } from './catalog/warehouse/warehouse.service';

@NgModule({
  imports: [FormsModule, UtilsModule, SidebarWrapperModule, FontAwesomeModule, ScfToastrModule],
  declarations: [HistoryComponent, HomeComponent, PageNotFoundComponent, SessionComponent],
  exports: [HistoryComponent, HomeComponent, PageNotFoundComponent],
  providers: [LabelService, ReleaseTaskService, SessionService, TaskGenericWrapperService, WarehouseService,
    DashboardConfigurationService, LocationService, DashboardService, UserService, PolicyParamService]
})
export class PagesModule { }

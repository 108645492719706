import { isEqual } from 'lodash';

export class AuthResponse {
  public maxAttemptsAllowed: number;
  public attemptsMade: number;
  public attemptsRemaining: number;
  public userBlocked: boolean;
  public message: string;

  /**
   * @description Validates if object is an instance of the class
   * @param {any} obj - Object to validate
   * @returns {boolean} - True if is an instance, false if not
   */
  public instanceOf(obj: any): boolean {
    if (isEqual(typeof obj, 'object')) {
      return isEqual(Object.keys(this.toJSON()), Object.keys(<Object>obj));
    }
    return false;
  }

  public toJSON(): Object {
    return {
      maxAttemptsAllowed: this.maxAttemptsAllowed,
      attemptsMade: this.attemptsMade,
      attemptsRemaining: this.attemptsRemaining,
      userBlocked: this.userBlocked,
      message: this.message
    };
  }
}

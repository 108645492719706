import { Account } from '../../catalog/account/account';
import { Module } from '../../module/module';

export class PolicyParam {
  account: Account;
  description: string;
  id: number;
  is_active: boolean;
  module: Module;
  name: string;
  rule: string;
  sequence: number;
  type: string;
  value: string;
  variable: string;

  constructor() {
    this.account = new Account();
    this.description = '';
    this.id = undefined;
    this.is_active = false;
    this.module = new Module();
    this.name = '';
    this.rule = '';
    this.sequence = 0;
    this.type = '';
    this.value = '';
    this.variable = '';
  }
}
